/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: normal;
  src: url("../public/montserrat/Montserrat-Thin.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-Thin.woff") format("woff");
}

/** Montserrat Thin-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: italic;
  src: url("../public/montserrat/Montserrat-ThinItalic.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-ThinItalic.woff") format("woff");
}

/** Montserrat ExtraLight **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: normal;
  src: url("../public/montserrat/Montserrat-ExtraLight.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-ExtraLight.woff") format("woff");
}

/** Montserrat ExtraLight-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: italic;
  src: url("../public/montserrat/Montserrat-ExtraLightItalic.woff2")
      format("woff2"),
    url("../public/montserrat/Montserrat-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Light **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("../public/montserrat/Montserrat-Light.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-Light.woff") format("woff");
}

/** Montserrat Light-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: italic;
  src: url("../public/montserrat/Montserrat-LightItalic.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-LightItalic.woff") format("woff");
}

/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("../public/montserrat/Montserrat-Regular.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-Regular.woff") format("woff");
}

/** Montserrat Regular-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  src: url("../public/montserrat/Montserrat-Italic.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-Italic.woff") format("woff");
}

/** Montserrat Medium **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("../public/montserrat/Montserrat-Medium.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-Medium.woff") format("woff");
}

/** Montserrat Medium-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: italic;
  src: url("../public/montserrat/Montserrat-MediumItalic.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-MediumItalic.woff") format("woff");
}

/** Montserrat SemiBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("../public/montserrat/Montserrat-SemiBold.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-SemiBold.woff") format("woff");
}

/** Montserrat SemiBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: italic;
  src: url("../public/montserrat/Montserrat-SemiBoldItalic.woff2")
      format("woff2"),
    url("../public/montserrat/Montserrat-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Bold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("../public/montserrat/Montserrat-Bold.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-Bold.woff") format("woff");
}

/** Montserrat Bold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: italic;
  src: url("../public/montserrat/Montserrat-BoldItalic.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-BoldItalic.woff") format("woff");
}

/** Montserrat ExtraBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: normal;
  src: url("../public/montserrat/Montserrat-ExtraBold.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-ExtraBold.woff") format("woff");
}

/** Montserrat ExtraBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: italic;
  src: url("../public/montserrat/Montserrat-ExtraBoldItalic.woff2")
      format("woff2"),
    url("../public/montserrat/Montserrat-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Black **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: url("../public/montserrat/Montserrat-Black.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-Black.woff") format("woff");
}

/** Montserrat Black-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: italic;
  src: url("../public/montserrat/Montserrat-BlackItalic.woff2") format("woff2"),
    url("../public/montserrat/Montserrat-BlackItalic.woff") format("woff");
}

/** =================== MONTSERRAT ALTERNATES =================== **/

/** Montserrat Alternates Thin **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 100;
  font-style: normal;
  src: url("../public/montserrat/MontserratAlternates-Thin.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-Thin.woff") format("woff");
}

/** Montserrat Alternates Thin-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 100;
  font-style: italic;
  src: url("../public/montserrat/MontserratAlternates-ThinItalic.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-ThinItalic.woff")
      format("woff");
}

/** Montserrat Alternates ExtraLight **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 200;
  font-style: normal;
  src: url("../public/montserrat/MontserratAlternates-ExtraLight.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-ExtraLight.woff")
      format("woff");
}

/** Montserrat Alternates ExtraLight-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 200;
  font-style: italic;
  src: url("../public/montserrat/MontserratAlternates-ExtraLightItalic.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-ExtraLightItalic.woff")
      format("woff");
}

/** Montserrat Alternates Light **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 300;
  font-style: normal;
  src: url("../public/montserrat/MontserratAlternates-Light.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-Light.woff") format("woff");
}

/** Montserrat Alternates Light-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 300;
  font-style: italic;
  src: url("../public/montserrat/MontserratAlternates-LightItalic.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-LightItalic.woff")
      format("woff");
}

/** Montserrat Alternates Regular **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 400;
  font-style: normal;
  src: url("../public/montserrat/MontserratAlternates-Regular.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-Regular.woff") format("woff");
}

/** Montserrat Alternates Regular-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 400;
  font-style: italic;
  src: url("../public/montserrat/MontserratAlternates-Italic.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-Italic.woff") format("woff");
}

/** Montserrat Alternates Medium **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 500;
  font-style: normal;
  src: url("../public/montserrat/MontserratAlternates-Medium.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-Medium.woff") format("woff");
}

/** Montserrat Alternates Medium-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 500;
  font-style: italic;
  src: url("../public/montserrat/MontserratAlternates-MediumItalic.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-MediumItalic.woff")
      format("woff");
}

/** Montserrat Alternates SemiBold **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 600;
  font-style: normal;
  src: url("../public/montserrat/MontserratAlternates-SemiBold.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-SemiBold.woff")
      format("woff");
}

/** Montserrat Alternates SemiBold-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 600;
  font-style: italic;
  src: url("../public/montserrat/MontserratAlternates-SemiBoldItalic.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-SemiBoldItalic.woff")
      format("woff");
}

/** Montserrat Alternates Bold **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 700;
  font-style: normal;
  src: url("../public/montserrat/MontserratAlternates-Bold.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-Bold.woff") format("woff");
}

/** Montserrat Alternates Bold-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 700;
  font-style: italic;
  src: url("../public/montserrat/MontserratAlternates-BoldItalic.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-BoldItalic.woff")
      format("woff");
}

/** Montserrat Alternates ExtraBold **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 800;
  font-style: normal;
  src: url("../public/montserrat/MontserratAlternates-ExtraBold.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-ExtraBold.woff")
      format("woff");
}

/** Montserrat Alternates ExtraBold-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 800;
  font-style: italic;
  src: url("../public/montserrat/MontserratAlternates-ExtraBoldItalic.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-ExtraBoldItalic.woff")
      format("woff");
}

/** Montserrat Alternates Black **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 900;
  font-style: normal;
  src: url("../public/montserrat/MontserratAlternates-Black.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-Black.woff") format("woff");
}

/** Montserrat Alternates Black-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 900;
  font-style: italic;
  src: url("../public/montserrat/MontserratAlternates-BlackItalic.woff2")
      format("woff2"),
    url("../public/montserrat/MontserratAlternates-BlackItalic.woff")
      format("woff");
}
